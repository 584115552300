import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/Styles.css";
import html2pdf from 'html2pdf.js';
import Dropdown from "react-bootstrap/Dropdown";
import { db } from '../../firebase/config';
import { doc, onSnapshot } from 'firebase/firestore';

const Header = ({ proposal }) => {
  const [shortlistData, setShortlistData] = useState([]);
  const proposalId = window.localStorage.getItem('proposalId');

  useEffect(() => {
    if (!proposalId) return;

    // Set up real-time listener for shortlist data
    const unsubscribe = onSnapshot(doc(db, 'proposals', proposalId), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setShortlistData(data.shortlist || []);
      }
    }, (error) => {
      console.error('Error fetching shortlist data:', error);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [proposalId]);

  const handleDownloadPDF = () => {
    const element = document.body;
    const opt = {
      margin: 0.5,
      filename: 'flex-proposal.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' }
    };

    html2pdf().set(opt).from(element).save();
  };

  // Check if there are any shortlisted options using Firestore data
  const hasShortlists = shortlistData?.length > 0;

  console.log(shortlistData);

  return (
    <header className="text-white p-3 fixed-top" style={{ backgroundColor: "#1F497D" }}>
      <div className="container">
        <div className="row w-100 justify-content-between align-items-center">
          {/* Title */}
          <div className="col-12 col-md-6 d-none d-md-block text-center text-md-start mb-3 mb-md-0">
            <p className="mb-0 text-white">
              Flex Office Space Proposal
            </p>
          </div>

          {/* Menu Items Row */}
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center gap-4">
            <a
              href="#RecommendedSpaces"
              className="text-white text-decoration-none header-menu"
            >
              Summary
            </a>
            <Dropdown>
              <Dropdown.Toggle
                variant="transparent"
                id="dropdown-basic"
                className="text-white header-menu"
              >
                Options Details
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {proposal?.proposedOptions?.map((option, index) => (
                  <Dropdown.Item key={index} href={`#${option.centerName}`}>
                    {option.centerName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <a
              href="#Short-list"
              className="text-white text-decoration-none header-menu"
            >
              Shortlist
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
