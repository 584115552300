import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDDe-I1Ry9VNCC1qAw817a3fk_-d_aLUNE",
  authDomain: "gofloaters-proposal-dev.firebaseapp.com",
  projectId: "gofloaters-proposal-dev",
  storageBucket: "gofloaters-proposal-dev.firebasestorage.app",
  messagingSenderId: "12087080561",
  appId: "1:12087080561:web:8999b66a1e422926cecdd0",
  measurementId: "G-LQNVBEWSZW"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
