import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Screen from './Pages/Screen';
import Login from './Pages/Login';
import flyloader from "./assest/image/Fetching Proposal.gif";
import "./index.css";

const App = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  console.log('App.js - Location:', location.search);
  console.log('App.js - ProposalId:', proposalId);

  return (
    <Routes>
      <Route path="/" element={<Login proposalId={proposalId} />} />
      <Route path="/proposal" element={<ProposalFetcher />} />
    </Routes>
  );
};

const ProposalFetcher = () => {
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  console.log('Location Search:', location.search);

  useEffect(() => {
    if (!proposalId) {
      setError('Mandatory proposal id missing from the URL. Please check the URL of the page');
      setLoading(false);
      return;
    }

    console.log('ProposalId in ProposalFetcher:', proposalId);

    const fetchProposalData = async () => {
      try {
        const authToken = window.localStorage.getItem('authToken');
        console.log('Auth Token in ProposalFetcher:', authToken);
        
        if (!authToken) {
          console.log('No auth token found, redirecting to login');
          navigate('/?proposalId=' + proposalId);
          return;
        }

        const baseUrl = 'https://gofloaters.firebaseapp.com';
        console.log('Fetching proposal data for ID:', proposalId);

        const response = await axios.get(`${baseUrl}/proposal/${proposalId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.data && response.data.data) {
          setProposal(response.data.data);
        } else {
          throw new Error('Invalid response format from server');
        }
      } catch (error) {
        console.error('Error fetching proposal:', error);
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            // Authentication error - redirect to login
            navigate('/?proposalId=' + proposalId);
            return;
          } else if (error.response.status === 500) {
            setError('Server error. Please try again later.');
          } else if (error.response.status === 404) {
            setError('Proposal not found. Please check the proposal ID.');
          } else {
            setError(error.response.data?.message || 'Error fetching proposal data');
          }
        } else if (error.request) {
          setError('No response from server. Please check your internet connection.');
        } else {
          setError('Error fetching proposal data. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProposalData();
  }, [proposalId, navigate]);

  if (loading) return (
    <div className='loader-head'>
      <img className='loadernew' src={flyloader} alt="Loading..." />
    </div>
  );

  if (error) return (
    <div style={{ textAlign: 'center', marginTop: '250px' }}>
      <p style={{ fontSize: 20, fontWeight: 600, color: '#dc3545' }}>{error}</p>
      <p style={{ fontSize: 16, color: '#666', marginTop: '1rem' }}>
        If the problem persists, please contact support.
      </p>
    </div>
  );

  if (!proposal) return null;

  return <Screen proposal={proposal} />;
};

export default App;
