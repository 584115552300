import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, db } from '../firebase/config';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import axios from 'axios';
import '../Styles/Login.css';

const Login = ({ proposalId }) => {
  const [email, setEmail] = useState('');
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [Proposal, setProposal] = useState('');
  const location = useLocation();
  console.log(Proposal, "Proposal");

  useEffect(() => {
    const fetchProposalData = async () => {
      if (proposalId) {
        try {
          const baseUrl = 'https://gofloaters.firebaseapp.com';
          const response = await axios.get(`${baseUrl}/proposal/${proposalId}`);
          if (response.data && response.data.data) {
            const proposalData = response.data.data;
            setProposal(proposalData);
          }
        } catch (error) {
          console.error('Error fetching proposal data:', error);
          setProposal("GoFloaters");
        }
      }
    };

    fetchProposalData();
  }, [proposalId]);

  useEffect(() => {

    if (proposalId) {
      console.log('ProposalId from URL:', proposalId);
      window.localStorage.setItem('proposalId', proposalId);
    }

    // Check if the user is coming back from email link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let emailFromStorage = window.localStorage.getItem('emailForSignIn');

      if (emailFromStorage) {
        setLoading(true);
        signInWithEmailLink(auth, emailFromStorage, window.location.href)
          .then(async (result) => {
            const idToken = await result.user.getIdToken();
            window.localStorage.setItem('authToken', idToken);
            console.log('Auth Token:', idToken);

            // Get the proposalId and update Firestore with API data
            const proposalId = window.localStorage.getItem('proposalId');
            console.log('ProposalId from localStorage:', proposalId);

            try {
              if (proposalId) {
                const proposalRef = doc(db, 'proposals', proposalId);

                // Update document with API data
                await updateDoc(proposalRef, {
                  clientName: Proposal.clientName || "Unknown Client",
                  requirements: Proposal.requirements || "No requirements specified"
                });
              }
            } catch (error) {
              console.error('Error updating proposal data:', error);
            }

            // Clear the email from storage
            window.localStorage.removeItem('emailForSignIn');

            // Get the proposalId and redirect
            if (proposalId) {
              window.location.href = `/proposal/?proposalId=${proposalId}`;
            }
          })
          .catch((error) => {
            console.error('Sign-in error:', error);
            if (error.code === 'auth/invalid-email') {
              setError('Invalid email address. Please try again.');
            } else if (error.code === 'auth/expired-action-code') {
              setError('The sign-in link has expired. Please request a new one.');
            } else {
              setError('Error signing in. Please try again.');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, []);

  // Auto-hide success message after 3 seconds
  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [success]);

  const handleSubmit = async () => {
    if (!email || !isChecked1 || !isChecked2) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      // Store proposalId in localStorage
      const searchParams = new URLSearchParams(location.search);
      const proposalId = searchParams.get('proposalId');
      if (proposalId) {
        console.log('ProposalId from URL:', proposalId);
        window.localStorage.setItem('proposalId', proposalId);
      }

      // Update Firestore
      try {
        const proposalRef = doc(db, 'proposals', proposalId);
        const proposalDoc = await getDoc(proposalRef);

        if (!proposalDoc.exists()) {
          // Create initial document with basic info
          await setDoc(proposalRef, {
            clientName: Proposal.clientName || "Unknown Client",
            requirements: Proposal.requirements || "No requirements specified",
            proposalDate: Timestamp.now(),
            viewers: [email]
          });
        } else {
          // Add email to viewers array if document exists
          await updateDoc(proposalRef, {
            viewers: arrayUnion(email)
          });
        }
      } catch (error) {
        console.error('Firestore error:', error);
        // Continue with email link even if Firestore fails
      }

      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);

      setSuccess(true);
      setEmail('');
      setIsChecked1(false);
      setIsChecked2(false);
    } catch (error) {
      console.error('Email link error:', error);
      if (error.code === 'auth/invalid-email') {
        setError('Invalid email address. Please check and try again.');
      } else if (error.code === 'auth/quota-exceeded') {
        setError('Too many requests. Please try again later.');
      } else {
        setError('Error sending email link. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="logo-container">
          <img src="https://assets.gofloaters.com/logo.png" alt="GoFloaters" />
          <p className="tagline">Spaces that work for you</p>
        </div>

        <h3 className="title">
          Flex Office Space Proposal {Proposal?.clientName}
        </h3>

        <div className="form-container">
          <div className="input-group">
            <input
              type="email"
              placeholder="Email id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              className="email-input"
            />
          </div>

          <div className="checkbox-group">
            <input
              type="checkbox"
              id="terms1"
              checked={isChecked1}
              onChange={(e) => setIsChecked1(e.target.checked)}
              disabled={loading}
              className="checkbox-input"
            />
            <label htmlFor="terms1" className="checkbox-label">
              I agree to receive the proposal details and understand that my email will be used for verification purposes only.
              The proposal information is confidential and intended solely for the recipient.
            </label>
          </div>

          <div className="checkbox-group">
            <input
              type="checkbox"
              id="terms2"
              checked={isChecked2}
              onChange={(e) => setIsChecked2(e.target.checked)}
              disabled={loading}
              className="checkbox-input"
            />
            <label htmlFor="terms2" className="checkbox-label">
              I understand and agree that these options were presented by GoFloaters and I grant them the authority to negotiate
              and close the deal on my behalf.
            </label>
          </div>

          {error && <div className="error-message">{error}</div>}

          {success && <div className="simple-success text-success">Email sent successfully. Please check your email</div>}

          <button
            className="continue-button"
            onClick={handleSubmit}
            disabled={!email || !isChecked1 || !isChecked2 || loading}
          >
            {loading ? 'Processing...' : 'Continue'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
