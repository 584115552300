import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Modal from "react-modal"; // Import react-modal
import "../Styles/Styles.css";
import ImageModal from "./ImageModel";

const Details = ({
    location,
    layouts,
    photos,
    virtualTour
}) => {
    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    // Function to handle image click to show in the modal
    const handleImageClick = (imageUrl) => {
        console.log("Image clicked:", imageUrl); // Check if this logs the correct URL
        setCurrentImage(imageUrl);
        setShowModal(true);
    };


    // Close modal
    const handleCloseModal = () => setShowModal(false);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const [key, setKey] = useState("photos");

    const { lat, lng } = location;



    return (
        // <div className="text-center align-items-center">
            <div className="my-4">
                <Tabs
                    id="media-tabs"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    {/* Photos Tab */}
                    <Tab eventKey="photos" title="Photos">
                        <div className="carousel-background">
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                responsive={responsive}
                                ssr={true}
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                containerClass="carousel-container"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                dotListClass="custom-dot-list-style"
                            >
                                {photos?.map((photo, index) => (
                                    <div key={index} onClick={() => handleImageClick(photo.url)}>
                                        <div className="image-container">
                                            <img
                                                src={photo.url} // Assuming the photo object has a 'url' property
                                                className="carousel-image"
                                                alt={photo.label || `Photo ${index + 1}`}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </Tab>


                    {/* Virtual Tour Tab - Only show if virtualTour exists and has a URL */}
                    {virtualTour?.url && (
                        <Tab eventKey="virtual-tour" title="Virtual Tour">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    src={virtualTour.url}
                                    width="100%"
                                    height="300px"
                                    allowFullScreen
                                    title="Virtual Tour"
                                    style={{ borderRadius: 18 }}
                                ></iframe>
                            </div>
                        </Tab>
                    )}

                    {/* Layout Tab */}
                    <Tab eventKey="layout" title="Layout">
                        <Carousel
                            responsive={responsive}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            ssr={true}
                            autoPlay={false}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            containerClass="carousel-container mb-4"
                            // removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                        >
                            {layouts?.map((layout, index) => (
                                <div key={index} onClick={() => handleImageClick(layout.url)} className="layout1">
                                    <img
                                        className="d-block w-100 img-responsive"
                                        src={layout.url} // Assuming the layout object has a 'url' property
                                        alt={layout.label || `Layout ${index + 1}`}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </Tab>

                    {/* Location Tab */}
                    <Tab eventKey="location" title="Location">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                title="Google Map Location"
                                src={`https://www.google.com/maps?q=${lat},${lng}&hl=es;z=14&output=embed`}
                                width="100%"
                                height="300px"
                                allowFullScreen
                                style={{ borderRadius: 18 }}
                            ></iframe>
                        </div>
                    </Tab>
                </Tabs>
                <ImageModal
                    isOpen={showModal}
                    onClose={handleCloseModal}
                    imageUrl={currentImage}
                />
            </div>
        // </div>
    );
};

export default Details;
