import React from 'react'
import OfficeProposal from '../Component/OfficeProposal/OfficeProposal'
import Aboutgofloater from '../Component/Aboutgofloater.js/Aboutgofloater'
import LargestNetwork from '../Component/LargestNetwork/LargestNetwork'
import OurFlexOffice from '../Component/OurFlexOffice/OurFlexOffice'
import Whychooseus from '../Component/WhyChooseUs/Whychooseus'
import RecommendedSpaces from '../Component/RecommendedSpaces/Recommendedspaces'
import Centerdetailscard from '../Component/Centerdetails/Centerdetailscard'
import Clientbase from '../Component/Client Base/Clientbase'
import Testimonials from '../Component/Testimonials/Testimonials'
import Conculsion from '../Component/Conculsion/Conculsion'
import Header from '../Component/Headercomponet/Header'
import Recommandedlocation from '../Component/Recommanded Location/Recommandedlocation'
import MobileNavigation from '../Component/MobileNavigation/MobileNavigation'
import ShortlistTable from '../Component/Shortlist Place/ShortlistTable'

export default function Screen({ proposal }) {
    return (
        <div>
            <Header proposal={proposal} />
            <div id="office-proposal">
                <OfficeProposal proposal={proposal} />
            </div>
            <div id="Short-list">
                <ShortlistTable proposal={proposal} />
            </div>
            <div id="recommended-locations">
                <Recommandedlocation proposal={proposal} />
            </div>
            <div id="recommended-spaces">
                <RecommendedSpaces proposal={proposal} />
            </div>
            <div id="center-details">
                <Centerdetailscard proposal={proposal} />
            </div>
            {/* <Aboutgofloater /> */}
            {/* <LargestNetwork /> */}
            {/* <OurFlexOffice /> */}
            {/* <Whychooseus /> */}
            {/* <Clientbase /> */}
            {/* <Testimonials /> */}
            <Conculsion />
            <MobileNavigation />
        </div>
    )
}
