import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Details from "./Details";
import backicon from "../assest/image/back.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faCalendarAlt, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import faClipboard from "../assest/image/1.png";
import faCalendar from "../assest/image/2.png";
import faInfoCircle from "../assest/image/3.png";
import Clipboard from "../assest/image/4.png";
import Notes from "../assest/image/5.png";
import Amenities from "../assest/image/meeting-place.png";
import Loactionicon from "../assest/image/location-pin.png";
import { auth, db } from '../firebase/config';
import { doc, updateDoc, arrayUnion, getDoc, Timestamp } from 'firebase/firestore';
import ShortlistTable from '../Component/Shortlist Place/ShortlistTable';
import '../Styles/Centerdetails.css'; // Keep your custom CSS if needed

const Centerdetails = ({
    centerName,
    inventoryDescription,
    billableSeats,
    pricePerSeat,
    totalRentPerMonth,
    amenities,
    termsAndConditions,
    location,
    layouts,
    photos,
    availableFrom,
    securityDeposit,
    virtualTour,
    bikeParkingCost,
    carParkingCost,
    option,
}) => {
    const [isShortlisted, setIsShortlisted] = useState(false);
    const [shortlistedUsers, setShortlistedUsers] = useState([]);
    const proposalId = window.localStorage.getItem('proposalId');

    // Check if already shortlisted and get all shortlisted users
    useEffect(() => {
        const checkShortlistStatus = async () => {
            if (!proposalId || !auth.currentUser) return;
            
            try {
                const proposalRef = doc(db, 'proposals', proposalId);
                const proposalDoc = await getDoc(proposalRef);
                
                if (proposalDoc.exists()) {
                    const data = proposalDoc.data();
                    const shortlistedItems = data.shortlist || [];
                    
                    // Find the current center in shortlist
                    const currentItem = shortlistedItems.find(item => 
                        item.inventoryId === centerName
                    );

                    if (currentItem) {
                        // Set shortlisted users
                        setShortlistedUsers(currentItem.shortlistedBy || []);
                        // Check if current user has shortlisted
                        const userShortlisted = currentItem.shortlistedBy.some(
                            user => user.email === auth.currentUser.email
                        );
                        setIsShortlisted(userShortlisted);
                    }
                }
            } catch (error) {
                console.error('Error checking shortlist status:', error);
            }
        };

        checkShortlistStatus();
    }, [proposalId, centerName]);

    const handleShortlist = async () => {
        if (!auth.currentUser || !proposalId) {
            console.error('User not authenticated or proposal ID missing');
            return;
        }

        try {
            const proposalRef = doc(db, 'proposals', proposalId);
            const proposalDoc = await getDoc(proposalRef);
            
            if (proposalDoc.exists()) {
                const data = proposalDoc.data();
                const shortlistedItems = data.shortlist || [];
                
                // Find if center is already in shortlist
                const existingItemIndex = shortlistedItems.findIndex(
                    item => item.inventoryId === centerName
                );

                if (existingItemIndex !== -1) {
                    // Center exists in shortlist
                    const existingItem = shortlistedItems[existingItemIndex];
                    
                    if (isShortlisted) {
                        // Remove current user from shortlistedBy
                        const updatedShortlistedBy = existingItem.shortlistedBy.filter(
                            user => user.email !== auth.currentUser.email
                        );
                        
                        if (updatedShortlistedBy.length === 0) {
                            // Remove entire item if no users left
                            shortlistedItems.splice(existingItemIndex, 1);
                        } else {
                            // Update shortlistedBy array
                            existingItem.shortlistedBy = updatedShortlistedBy;
                            shortlistedItems[existingItemIndex] = existingItem;
                        }
                    } else {
                        // Add current user to existing shortlistedBy array
                        existingItem.shortlistedBy.push({
                            email: auth.currentUser.email,
                            timeStamp: Timestamp.now()
                        });
                        shortlistedItems[existingItemIndex] = existingItem;
                    }
                } else if (!isShortlisted) {
                    // Add new shortlist item
                    shortlistedItems.push({
                        inventoryId: centerName,
                        shortlistedBy: [{
                            email: auth.currentUser.email,
                            timeStamp: Timestamp.now()
                        }]
                    });
                }

                // Update Firestore
                await updateDoc(proposalRef, {
                    shortlist: shortlistedItems
                });

                // Update local state
                setIsShortlisted(!isShortlisted);
                setShortlistedUsers(
                    isShortlisted 
                        ? shortlistedUsers.filter(user => user.email !== auth.currentUser.email)
                        : [...shortlistedUsers, { email: auth.currentUser.email, timeStamp: Timestamp.now() }]
                );
            }
        } catch (error) {
            console.error('Error updating shortlist:', error);
        }
    };

    const { address } = location;

    const formatPrice = (price) =>
        price !== undefined && price !== null
            ? new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", maximumFractionDigits: 0 }).format(price)
            : "N/A";

    return (
        <div className="mt-5" id="Details">
            <div className="container">
                
                <div className="row gy-4">
                    {/* Left Column */}
                    <div className="col-md-6">
                    <h2 className="mb-1" id={`${centerName}`}>
                    <a href="#RecommendedSpaces">
                        <img src={backicon} width="20px" alt="Back" className="me-2" />
                    </a>
                    {centerName}
                </h2>
                        <div className="address text-start">
                            <div className="row">
                                <div className="col-1">
                                    <img src={Loactionicon} width="20px" alt="Location" className="m-2" />
                                </div>
                                <div className="col-11 px-3">
                                    <p>{address}</p>
                                </div>
                            </div>
                        </div>
                        <Details location={location} layouts={layouts} photos={photos} virtualTour={virtualTour} />
                        <div className="d-flex flex-wrap justify-content-around mb-3">
                            <button 
                                className="CentercardButton mb-2"
                                onClick={handleShortlist}
                                title={isShortlisted ? 'Click to remove from shortlist' : 'Click to shortlist'}
                            >
                                <FontAwesomeIcon 
                                    icon={faHeart} 
                                    className={`me-2 ${isShortlisted ? 'text-danger' : 'text-secondary'}`}
                                />
                                Shortlist
                            </button>
                            {/* <button className="CentercardButton  mb-2">
                                <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />
                                Schedule Tour
                            </button>
                            <button className="CentercardButton mb-2">
                                <FontAwesomeIcon icon={faCommentDots} className="me-2" />
                                Feedback
                            </button> */}
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="col-md-6 mb-5" >
                        <div>
                            <div className="p-md-4 p-0 bg-light rounded p-2 option-details">
                                {/* Inventory Section */}
                                <div className="mb-4">
                                    <p className="fw-bold tableicon-head">
                                        <img src={faClipboard} alt="Inventory" className="me-2" width={26} />
                                        Inventory
                                    </p>
                                    <p className="tableicon-content">
                                        {inventoryDescription || "As per requirement"} <br />
                                        <span className="">Capacity:</span> {billableSeats || "N/A"} billable seats <br />
                                        <span className="">Operator:</span> {option.operatorName}
                                    </p>
                                </div>
                            <hr/>
                                {/* Availability Section */}
                                <div className="mb-4">
                                    <p className="fw-bold tableicon-head">
                                        <img src={faCalendar} alt="Availability" className="me-2" width={26} />
                                        Availability
                                    </p>
                                    <p className="tableicon-content">{availableFrom} </p>
                                </div>
                                <hr/>
                                {/* Pricing Section */}
                                <div className="mb-4">
                                    <p className="fw-bold tableicon-head">
                                        <img src={faInfoCircle} alt="Pricing" className="me-2" width={26} />
                                        Pricing
                                    </p>
                                    <p className="tableicon-content">
                                        Rent per desk: {formatPrice(pricePerSeat)}
                                    </p>
                                    <p className="tableicon-content">
                                        Total rent per month: {formatPrice(totalRentPerMonth)}
                                    </p>
                                    <p className="tableicon-content">
                                        Parking: Bike - {formatPrice(bikeParkingCost)}, Car - {formatPrice(carParkingCost)}
                                    </p>
                                    <p className="tableicon-content">
                                        Security Deposit: {formatPrice(securityDeposit)}
                                    </p>
                                </div>
                                <hr/>
                                {/* Amenities Section */}
                                <div className="mb-4">
                                    <p className="fw-bold tableicon-head">
                                        <img src={Amenities} alt="Amenities" className="me-2" width={26} />
                                        Amenities
                                    </p>
                                    <ul className="row list-unstyled tableicon-content">
                                        {amenities.map((amenity, index) => (
                                            <li key={index} className="col-6 tableicon-content">
                                                <p>• {amenity}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <hr/>
                                {/* Terms and Conditions Section */}
                                <div className="mb-4">
                                    <p className="fw-bold tableicon-head">
                                        <img src={Clipboard} alt="Terms and Conditions" className="me-2" width={26} />
                                        Terms and Conditions
                                    </p>
                                    <span className="mx-0 p-0 text-start tableicon-content">
                                        <p>Lockin Period  : {option.lockinPeriod || "N/A"} months<br /></p>
                                        <p>Notice period : {option.noticePeriod || "N/A"} months<br /></p>
                                        <p>Annual Escalation : {option.annualEscalation || "N/A"}%<br /></p>
                                        <p>Complimentary Meeting Room Credits : {option.complimentaryMeetingRoomCredits || "N/A"}<br /></p>
                                        <p>Complimentary Car Parking : {option.complimentaryCarParkSlots || "N/A"} Slots<br /></p>
                                        <p>Complimentary Bike Parking : {option.complimentaryBikeParkSlots || "N/A"} Slots<br /></p>
                                        <p>{option.terms || "N/A"}</p>
                                    </span>
                                </div>
                                <hr/>
                                {/* Notes Section */}
                                <div>
                                    <p className="fw-bold tableicon-head">
                                        <img src={Notes} alt="Notes" className="me-2" width={26} />
                                        Notes
                                    </p>
                                    <p className="tableicon-content">
                                        {option.floor && <span>Floor: {option.floor}</span>}
                                        <br />
                                        Nearest Metro: {option.nearestMetro || "N/A"}
                                        <br />
                                        Operating Hours: {option.operationHours || "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        </div>
    );
};

export default Centerdetails;
